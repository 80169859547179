
@if (isWelcome) {
  <div class="welcome" [ngClass]="{'welcome--text': !point.poi.image, 'welcome--logged-user': isLoggedIn}">
      <div class="welcome__wrapper">
        <spaceshout-button
          class="welcome__wrapper__close"
          [priority]="'senary'"
          [size]="'roundedSmall'"
          [icon]="'closeCircleFilled'"
          (clicked)="onClose()"
        >
        </spaceshout-button>

        <div class="welcome__wrapper__container">
          <shout-svg-icons class="welcome__wrapper__container__logo" [name]="'spaceshoutLogo'"></shout-svg-icons>

          <h1>{{'map.welcome.title' | translate}}</h1>
          <h2>{{ 'map.welcome.sub_title' | translate: { name: point.title } }}</h2>
          <div class="welcome__wrapper__container__subtitle">
            <p>{{'map.welcome.sub_title2' | translate}}</p>
            <p>{{'map.welcome.sub_title3' | translate}}</p>
            <p>...</p>
          </div>

          <div class="welcome__wrapper__container__cta">
            <spaceshout-button
              *ngIf="!isLoggedIn"
              [type]="'submit'"
              [label]="'map.welcome.signUpBtnLabel' | translate"
              (clicked)="onSignUp()"
            >
            </spaceshout-button>
            <spaceshout-button
                *ngIf="!isLoggedIn"
                [priority]="'secondary'"
                [label]="'map.welcome.signInBtnLabel' | translate"
                (clicked)="onSignIn()"
            >
            </spaceshout-button>
          
          </div>

          <div class="welcome__wrapper__container__external-methods">
            <img src="assets/appstore.webp" alt="Download app from app store" (click)="onGetFromAppStore()">
            <img src="assets/googleplay.webp" alt="Download app from google play store" (click)="onGetFromGooglePlay()">
          </div>
        </div>
      </div>
    <div class="welcome__overlay"></div>
  </div>
}

@if (isMarkerAndLabelOnly) {
  <div class="point-with-marker-only">
    <div class="point-with-marker-only__label">
      <label>{{point.poi?.address}}</label>
      <spaceshout-button
        *ngIf="point?.poi?.userId?.toString() === userId.toString()"
        [priority]="'quaternary'"
        [size]="'roundedSmall'"
        [icon]="'pencil'"
        [tooltip]="'map.overwrite_address_tooltip' | translate"
        (clicked)="onSelectedAddressOverwrite()"
      >
      </spaceshout-button>
    </div>
    <div class="point-on-map__pointer">
      <div class="point-on-map__pointer__element">
          <img src="assets/marker.svg" alt="">
      </div>
    </div>
  </div>
}

@if (point?.poi?.images?.length > 0 && !isMarkerAndLabelOnly) {
  <shout-point-with-image
    #pointWithImage
    [point]="point"
    [blockEvents]="mapService.blockClickEventsOnPoints | async"
    [searchType]="searchType"
    [hasSelectedItem]="hasSelectedItem"
    [isCollectionHintVisible]="mapService.isCollectionHintVisible"
    [image_path]="IMAGE_PATH"
    [showMinimalisticVersion]="(storeDataService.viewPointsPanelToggle | async) && !isActiveOnMap"
    [hidePoisCounter]="storeDataService.isWallOpen$ | async"
    (clicked)="onPointClick()"
    (wheeled)="onWheel($event)"
    (poisCountClick)="onPoisCountClick()"
  ></shout-point-with-image>
} @else if (!isMarkerAndLabelOnly) {
  <shout-text-point
    #textPoint
    [point]="point"
    [blockEvents]="mapService.blockClickEventsOnPoints | async"
    [searchType]="searchType"
    [hasSelectedItem]="hasSelectedItem"
    [isCollectionHintVisible]="mapService.isCollectionHintVisible"
    [isWelcome]="isWelcome"
    [description]="description"
    [showMinimalisticVersion]="(storeDataService.viewPointsPanelToggle | async) && !isActiveOnMap"
    [hidePoisCounter]="storeDataService.isWallOpen$ | async"
    (clicked)="onPointClick()"
    (wheeled)="onWheel($event)"
    (poisCountClick)="onPoisCountClick()"
  ></shout-text-point>
}
